import logo from "../../assets/images/shared/logo.svg";
import logoutIcon from "../../assets/images/shared/logout.svg";
import styles from "./header.scss";

import { resolve } from "inversify-react";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { AuthService } from "../auth/authService";
import { Button } from "../shared/button";
import { Version } from "../shared/version";
import { TYPES } from "../user/di";
import { UserService } from "../user/userService";

@observer
class Header extends Component {

	@resolve(TYPES.Auth) private readonly authService: AuthService;
	@resolve(TYPES.User) private readonly userService: UserService;

	render() {
		const { user } = this.userService;
		return (
			<nav style={styles.nav}>
				<div
					className={`container padded ${styles.container}`}
					title={Version.versionString}
				>
					<img src={logo} className={styles.logo} />
					<h1 className="title">Sunday</h1>
					<div className={styles.actions}>
						{this.props.children}
					</div>
					{(
						<Button
							onClick={this.authService.logout}
							styleType="link"
							title="Logout"
						>
							{ user && user.displayName || this.authService.session.email || "User" }
							<img
								src={logoutIcon}
								className={styles.logoutIcon}
							/>
						</Button>
					)}
				</div>
			</nav>
		);
	}
}

export default Header;
