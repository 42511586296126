import styles from "./animatorFlow.scss";

import { t } from "i18n-js";
import { Container } from "inversify";
import { Provider, resolve } from "inversify-react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import { Structure } from "../admin/structure";
import Header from "../nav/header";
import { Error404 } from "../shared";
import { Animator } from "../user/user";
import { UserService } from "../user/userService";
import { BoxListScreen } from "./box/boxListScreen";
import { BoxService } from "./box/boxService";
import { TYPES } from "./di";
import { DocumentListScreen } from "./document/documentListScreen";
import { DocumentService } from "./document/documentService";
import { UploadService } from "./document/uploadService";

@observer
export class AnimatorFlow extends Component {

	@resolve(TYPES.User) private readonly userService: UserService;

	@computed private get animator(): Animator { return this.userService.user as Animator; }
	@computed private get structures(): Map<string, Structure> {
		return new Map(this.animator.structures.map<[string, Structure]>(s => [s.id, s]));
	}

	render() {
		if (this.structures.size === 0) { return (<Error404 />); }
		return (
			<Switch>
				<Route strict path="/structure/:structureId/" component={this.renderStructureFlow} />
				<Redirect exact from="/structure" to={`/structure/${this.structures.keys().next().value}/`} />
				<Redirect strict exact from="/" to={`/structure/${this.structures.keys().next().value}/`} />
				<Route component={Error404} />
			</Switch>
		);
	}

	private renderStructureFlow = ({ match }: any) => {
		const structure = this.structures.get(match.params.structureId);
		if (!structure) {
			return (
				<Error404 />
			);
		}

		return (
			<StructureFlow
				structure={structure}
				match={match}
			/>
		);
	}
}

interface StructureFlowProps { structure: Structure; match: any; }
class StructureFlow extends Component<StructureFlowProps> {

	private container: Container;

	constructor(props: StructureFlowProps) {
		super(props);
		this.container = new Container();
		this.container.bind<Structure>(TYPES.Structure).toConstantValue(this.props.structure);
		this.container.bind<BoxService>(TYPES.Box).to(BoxService).inSingletonScope();
		this.container.bind<DocumentService>(TYPES.Document).to(DocumentService).inSingletonScope();
		this.container.bind<UploadService>(TYPES.Upload).to(UploadService).inSingletonScope();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.structure !== this.props.structure) {
			this.container.rebind<Structure>(TYPES.Structure).toConstantValue(this.props.structure);
		}
	}

	componentWillUnmount() {
		this.container.unbindAll();
		console.log("StructureFlow: container was unbound");
	}

	render() {
		return (
			<>
				<Header>
					<NavLink
						to={`${this.props.match.url}document`}
						className={styles.link}
						activeClassName={styles.active}
					>
						<div className={styles.navLink}>
							{t("animator.menus.document")}
						</div>
					</NavLink>
					<NavLink
						to={`${this.props.match.url}box`}
						className={styles.link}
						activeClassName={styles.active}
					>
						<div className={styles.navLink}>
							{t("animator.menus.boxes")}
						</div>
					</NavLink>
				</Header>
				<Provider container={this.container}>
					<Switch>
						<Route exact strict path={`${this.props.match.url}document`} component={DocumentListScreen} />
						<Route exact strict path={`${this.props.match.url}box`} component={BoxListScreen} />
						<Redirect to={`${this.props.match.url}document`}/>
					</Switch>
				</Provider>
			</>
		);
	}
}
