import closeIcon from "../../assets/images/shared/close.svg";
import styles from "./modal.scss";

import React, { PureComponent } from "react";
import ReactModal from "react-modal";

interface ModalProps extends ReactModal.Props {
	title: string;
}

export class Modal extends PureComponent<ModalProps> {
	static defaultProps: Partial<ModalProps> = {
		className: styles.modal,
		overlayClassName: styles.overlay,
		shouldCloseOnOverlayClick: false,
		shouldCloseOnEsc: true,
	};

	static setAppElement = ReactModal.setAppElement;

	static styles = {
		button: styles.button,
		buttonRow: styles.buttonRow,
		row: styles.row,
	};

	render() {
		const { children, title, ...props } = this.props;
		return (
			<ReactModal {...props}>
				<header className={styles.header}>
					<h1>{this.props.title}</h1>
				</header>
				{children}
				<button
					className={styles.closeButton}
					aria-label="Close modal"
					onClick={props.onRequestClose as any}
				>
					<img src={closeIcon} className={styles.closeIcon} />
				</button>
			</ReactModal>
		);
	}
}
