import Axios, { AxiosInstance, AxiosResponse } from "axios";
import { inject, injectable } from "inversify";
import { TYPES } from "../di";

@injectable()
export class UploadService {
	constructor(
		@inject(TYPES.HTTPClient) private readonly httpClient: AxiosInstance,
		@inject(TYPES.APIClient) private readonly apiClient: AxiosInstance
	) {}

	async upload(content: Blob): Promise<Upload> {
		const initiation = await this.initiate(content);
		if (initiation.partsUrls.length !== 1) {
			throw new Error("Chunked upload is not supported yet");
		}
		const etag = await this.uploadTo(content, initiation.partsUrls[0]);

		return this.complete(initiation.uploadId, [etag]);
	}

	private async initiate(content: Blob): Promise<UploadInitiationResponse> {
		// We do not handle chunked uploads yet
		const { data: response }: AxiosResponse<UploadInitiationResponse> = await this.apiClient.post("/upload/media/initiate", {
			contentType: content.type,
			contentLength: content.size,
			partSize: content.size
		});
		return response;
	}

	private async uploadTo(content: Blob, url: string): Promise<string> {
		const response = await this.httpClient.put(url, content);
		const headers = response.headers;
		const etag = headers[Object.keys(headers).find(h => /etag/i.test(h))!];
		return etag;
	}

	private async complete(uploadId: string, partsEtags: string[]): Promise<Upload> {
		const { data: upload }: AxiosResponse<UploadResponse> = await this.apiClient.post("/upload/media/complete", { uploadId, partsEtags});
		return { id: upload.uploadId };
	}

}

interface UploadInitiationResponse {
	uploadId: string;
	partsUrls: string[];
}

interface UploadResponse {
	uploadId: string;
}

export interface Upload {
	id: string;
}
