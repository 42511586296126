import { resolve } from "inversify-react";
import { observer } from "mobx-react";
import { Component } from "react";
import React from "react";
import { TYPES } from "../di";
import { Alert } from "./alert";
import { EventService } from "./eventService";

@observer
export class EventsDisplay extends Component<{}> {

	@resolve(TYPES.Events)
	private readonly eventService: EventService;

	render() {
		return this.eventService.events.map((event, index) => (
			<Alert
				key={index}
				message={event.message}
				type={event.type}
				onDimiss={() => this.eventService.events.splice(index, 1) }
			/>
		));
	}
}
