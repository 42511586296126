import avatarStub from "~/assets/images/stubs/avatar.svg";
import styles from "./structureHeader.scss";

import { resolve } from "inversify-react";
import React, { PureComponent } from "react";
import { Structure } from "../admin/structure";
import { TYPES } from "./di";

export class StructureHeader extends PureComponent<React.HTMLProps<HTMLElement>> {
	@resolve(TYPES.Structure) private readonly structure: Structure;

	render() {
		return (
			<div className={`${styles.container} ${this.props.className}`}>
				<div className={styles.avatarContainer}>
					<img
						src={this.structure.avatarUrl || avatarStub}
						className={styles.avatar}
					/>
				</div>
				<h1 className={styles.name}>{this.structure.displayName}</h1>
			</div>
		);
	}
}
