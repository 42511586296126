import styles from "./userFlow.scss";

import Axios, { AxiosInstance } from "axios";
import { t } from "i18n-js";
import { Container } from "inversify";
import { Provider, resolve } from "inversify-react";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { AdminFlow } from "../admin/adminFlow";
import { AnimatorFlow } from "../animator";
import { AuthService } from "../auth/authService";
import { JWTSession } from "../auth/jwtSession";
import Header from "../nav/header";
import { Button, LoadingScreen } from "../shared";
import { TYPES } from "./di";
import { UserService } from "./userService";

interface UserFlowProps {
	session: JWTSession;
}

@observer
export class UserFlow extends Component<UserFlowProps> {

	private container: Container;
	private readonly userService: UserService;

	@resolve(TYPES.Auth) private readonly authService: AuthService;

	constructor(props: UserFlowProps) {
		super(props);
		this.container = new Container();
		this.container.bind<AxiosInstance>(TYPES.APIClient).toConstantValue(this.buildAPIClient());
		this.container.bind<UserService>(TYPES.User).to(UserService).inSingletonScope();

		this.userService = this.container.get(TYPES.User);
	}

	componentWillUnmount() {
		this.container.unbindAll();
	}

	render() {
		return (
			<Provider container={this.container}>
				<>
					{
						!(this.userService && this.userService.initialized)
						? <LoadingScreen />
						: (
							<Switch>
								{this.userService.user.isAdmin && <Route path="/" component={AdminFlow} />}
								{this.userService.user.isAnimator && <Route path="/" component={AnimatorFlow} />}
								<Route path="/" component={FallbackUserFlow} />
							</Switch>
						)
					}
				</>
			</Provider>
		);
	}

	private buildAPIClient = () => {
		const client = Axios.create({
			baseURL: process.env.API_BASE_URL,
		});
		client.interceptors.request.use(async (config) => {
			if (!config.headers) { config.headers = {}; }
			config.headers.Authorization = `Bearer ${await this.authService.session.token}`;
			return config;
		});
		return client;
	}
}

class FallbackUserFlow extends Component {
	@resolve(TYPES.Auth) private readonly authService: AuthService;

	render() {
		return (
			<>
				<Header />
				<div className={`container ${styles.container}`}>
					<h1 className={styles.title}>{t("common.errors.not_professional.title")}</h1>
					<p className={styles.text}>{t("common.errors.not_professional.body")}</p>
					<Button onClick={this.authService.logout}>{t("common.errors.not_professional.logout")}</Button>
				</div>
			</>
		);
	}
}
