import Axios, { AxiosInstance } from "axios";
import { Container } from "inversify";
import getDecorators from "inversify-inject-decorators";
import { AuthService, AuthServiceImpl } from "../auth/authService";
import { EventService } from "../shared/eventService";
import { ImageService } from "../shared/imageService";
import { TYPES } from "./types";

const container = new Container();
container.bind<AuthService>(TYPES.Auth).to(AuthServiceImpl).inSingletonScope();
container.bind<ImageService>(TYPES.Image).to(ImageService).inSingletonScope();
container.bind<EventService>(TYPES.Events).to(EventService).inSingletonScope();
container.bind<AxiosInstance>(TYPES.HTTPClient).toConstantValue(Axios.create());

const { lazyInject } = getDecorators(container);
export { lazyInject, container };
