import firebase, { FirebaseError } from "firebase/app";
import "firebase/auth";
import { t } from "i18n-js";
import { injectable } from "inversify";
import { computed, observable } from "mobx";
import { JWTSession } from "./jwtSession";

const credentialsErrorFirebaseCodeMapping = [
	"auth/wrong-password",
	"auth/user-not-found",
	"auth/invalid-email",
];

export interface AuthService {
	login(credentials: Credentials): Promise<void>;
	logout(): Promise<void>;
	resetPassword(infos: PasswordResetInfo): Promise<void>;
	readonly session: JWTSession | null;
	readonly initialized: boolean;
}

@injectable()
export class AuthServiceImpl implements AuthService {
	@observable session: JWTSession | null | undefined;
	@computed get initialized(): boolean { return this.session !== undefined; }

	private readonly auth: firebase.auth.Auth;

	constructor() {
		if (!firebase.apps.length) {
			firebase.initializeApp({
				apiKey: process.env.FIREBASE_API_KEY,
				authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
				databaseURL: `https://${process.env.FIREBASE_DATABASE_URL}.firebaseio.com`,
				storageBucket: `${process.env.FIREBASE_BUCKET}.appspot.com`,
			});
		}

		this.auth = firebase.auth();
		this.auth.onAuthStateChanged(this.onFirebaseAuthStateChanged);
	}

	login = async (credentials: Credentials) => {
		try {
			const { email, password } = credentials;
			await this.auth.signInWithEmailAndPassword(email, password);
		} catch (err) {
			const { code } = err as FirebaseError;
			if (credentialsErrorFirebaseCodeMapping.indexOf(code) >= 0) {
				throw new CredentialsError();
			} else if (code.startsWith("auth/")) {
				throw new ConnectionError();
			} else {
				throw new SundayError(t("auth.errors.default"));
			}
		}
	}

	logout = async () => {
		try {
			await this.auth.signOut();
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	resetPassword = async ({ email }: PasswordResetInfo) => {
		try {
			await this.auth.sendPasswordResetEmail(email);
		} catch (err) {
			console.error(err);
			throw new SundayError(t("auth.errors.default"));
		}
	}

	private onFirebaseAuthStateChanged = async (user: firebase.User | null) => {
		if (!user) {
			this.session = null;
		} else {
			this.session = new JWTSession(user);
		}
	}
}

export class SundayError extends Error {
	constructor(message: string) {
		super(message);
	}
}

export class CredentialsError extends SundayError { constructor() { super(t("auth.errors.credentials")); } }
export class ConnectionError extends SundayError { constructor() { super(t("auth.errors.connection")); } }

interface Credentials {
	email: string;
	password: string;
}

interface PasswordResetInfo {
	email: string;
}
