import { injectable } from "inversify";
import { observable } from "mobx";

export interface Event {
	message: string;
	type: "success" | "danger";
}

@injectable()
export class EventService {

	@observable events: Event[] = [];

}
