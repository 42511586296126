import { TYPES as BASE_TYPES } from "../di";
import { TYPES as USER_TYPES } from "../user/di";

export const TYPES = {
	...BASE_TYPES,
	...USER_TYPES,
	Box: Symbol.for("Box"),
	Document: Symbol.for("Document"),
	Structure: Symbol.for("Structure"),
	Upload: Symbol.for("Upload"),
};
