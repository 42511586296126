import React, { PureComponent } from "react";

import styles from "./alert.scss";

interface AlertProps {
	type: "danger" | "success";
	message: string;
	show: boolean;
	onDimiss?: () => void;
}

export class Alert extends PureComponent<AlertProps> {
	static defaultProps = {
		show: true
	};

	render() {
		const { type, message, show, onDimiss } = this.props;
		return ( show && message ?
			<div className={`${styles.alert} ${styles[type]}`}>
				{ onDimiss &&
					<button type="button" className={styles.close} aria-label="Close" onClick={onDimiss}>
						<span aria-hidden="true">&times;</span>
					</button>
				}
				{message}
			</div>
			: null
		);
	}
}
