import boxAdd from "~/assets/images/box/box_add.svg";
import detachIcon from "~/assets/images/box/detach.svg";
import inviteIcon from "~/assets/images/box/invite.svg";
import avatarStub from "~/assets/images/stubs/avatar.svg";
import styles from "./boxListScreen.scss";

import bell from "/assets/images/box/code/bell.svg";
import candy from "/assets/images/box/code/candy.svg";
import clover from "/assets/images/box/code/clover.svg";
import deleteButtonIcon from "/assets/images/box/code/delete.svg";
import feather from "/assets/images/box/code/feather.svg";
import heart from "/assets/images/box/code/heart.svg";
import home from "/assets/images/box/code/home.svg";
import moon from "/assets/images/box/code/moon.svg";
import note from "/assets/images/box/code/note.svg";
import star from "/assets/images/box/code/star.svg";
import sun from "/assets/images/box/code/sun.svg";

import { t } from "i18n-js";
import { resolve } from "inversify-react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEventHandler, Component, FormEventHandler, MouseEventHandler, PureComponent } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Structure } from "../../admin/structure";
import { Button, Card, Input, Modal } from "../../shared";
import { FormState } from "../../shared/formState";
import { TYPES } from "../di";
import { StructureHeader } from "../structureHeader";
import { Box } from "./box";
import BoxInvitationForm from "./boxInvitationForm";
import { BoxInvitationFormState } from "./boxInvitationForm";
import { BindingRequest, BoxService, PairingRequest } from "./boxService";

interface BoxListScreenState {
	isCreating: boolean;
	isInviting: boolean;
	invitationError?: string;
	boundedBox?: Box;
	invitationBox?: Box;
}

@observer
export class BoxListScreen extends Component<{}, BoxListScreenState> {
	state: BoxListScreenState = {
		isCreating: false,
		isInviting: false,
	};

	@resolve(TYPES.Structure) private readonly structure: Structure;
	@resolve(TYPES.Box) private readonly boxService: BoxService;

	@computed private get containerStyle(): string {
		const containerStyles = ["container", styles.container];
		if (this.boxService.initialized && !this.boxService.all.length) {
			containerStyles.push(styles.grow);
		}

		return containerStyles.join(" ");
	}

	render() {
		return (
			<>
				<main className={this.containerStyle}>
					<header className={styles.header}>
						<StructureHeader className={styles.structureHeader} />
						<Button styleType="bordered"  onClick={this.openCreation}>{t("animator.box.list.action_add")}</Button>
					</header>
					{
						this.boxService.all.length
						? (
							<InfiniteScroll
								element="ul"
								initialLoad={false}
								loadMore={this.fetchNext}
								hasMore={this.boxService.hasMore}
								className={styles.boxList}
							>{
								this.boxService.all.map(b => (
									<li
										key={b.id}
										className={styles.boxItemContainer}
									>
										<BoxItem
											box={b}
											bindResident={this.openBinding}
											detachResident={this.detachResident}
											inviteMember={this.openInvitation}
										/>
									</li>
								))
							}</InfiniteScroll>
						) : (
							<div className={styles.empty}>
								<img src={boxAdd} />
								<h2 className={styles.emptyTitle}>{t("animator.box.list.empty_title")}</h2>
								<p className={styles.emptyText}>{t("animator.box.list.empty_description")}</p>
								<Button onClick={this.openCreation}>{t("animator.box.list.action_add")}</Button>
							</div>
						)
					}
				</main>
				<Modal
					isOpen={this.state.isCreating}
					onRequestClose={this.closeCreation}
					title={t("animator.box.create.title")}
				>
					<BoxCreationForm
						onCreationCompleted={this.onCreationCompleted}
						onCloseRequested={this.closeCreation}
					/>
				</Modal>
				{
					this.state.boundedBox &&
					<Modal
						isOpen={true}
						onRequestClose={this.closeBinding}
						title={t("animator.box.create.title")}
					>
						<BoxBindingForm
							box={this.state.boundedBox}
							onBindingCompleted={this.onBindingCompleted}
							onCloseRequested={this.closeBinding}
						/>
					</Modal>
				}
				{
					this.state.invitationBox &&
					<Modal
						isOpen={true}
						onRequestClose={this.closeInvitation}
						title={t("animator.box.invite.title")}
					>
						<BoxInvitationForm
							box={this.state.invitationBox}
							loading={this.state.isInviting}
							error={this.state.invitationError}
							onInvitationCompleted={this.onInvitationCompleted}
							onCloseRequested={this.closeInvitation}
						/>
					</Modal>
				}
			</>
		);
	}

	private openCreation = () => this.setState({ isCreating: true });
	private closeCreation = () => this.setState({ isCreating: false });
	private onCreationCompleted = async ({ pairingCode, roomNumber, residentName }: BoxCreationFormState): Promise<void> => {
		const request: PairingRequest = {
			structureId: this.structure.id,
			roomNumber,
			residentName,
			pairingCode: pairingCode.join(""),
		};
		await this.boxService.create(request);
		this.closeCreation();
	}

	private openBinding = (boundedBox: Box) => this.setState({ boundedBox });
	private closeBinding = () => this.setState({ boundedBox: undefined });
	private onBindingCompleted = async ({ residentName }: BoxBindingFormState) => {
		const request: BindingRequest = {
			boxId: this.state.boundedBox!.id,
			residentName
		};
		await this.boxService.pair(request);
		this.closeBinding();
	}

	private detachResident = async (box: Box) => {
		if (confirm(t("animator.box.list.action_detach_grant"))) {
			this.boxService.detach({ boxId: box.id });
		}
	}

	private openInvitation = (invitationBox: Box) => this.setState({ invitationBox });
	private closeInvitation = () => this.setState({ invitationBox: undefined });
	private onInvitationCompleted = async (invitation: BoxInvitationFormState) => {
		try {
			this.setState({isInviting: true, invitationError: null});
			await this.boxService.invite({
				boxId: this.state.invitationBox.id,
				code: invitation.code,
				email: invitation.email,
			});
			this.closeInvitation();
		} catch (err) {
			this.setState({ invitationError: t("animator.box.errors.default") });
		} finally {
			this.setState({ isInviting: false });
		}
	}

	private fetchNext = () => this.boxService.fetchNext();
}

interface BoxItemProps {
	box: Box;
	bindResident: (box: Box) => void;
	detachResident: (box: Box) => void;
	inviteMember: (box: Box) => void;
}

class BoxItem extends PureComponent<BoxItemProps> {
	render() {
		return this.props.box.paired
			? this.renderPaired()
			: this.renderUnpaired();
	}

	private renderPaired() {
		const { box: { roomNumber, residentName }} = this.props;
		const title = `${roomNumber} • ${residentName}`;
		return (
			<Card className={styles.boxItem}>
				<div className={styles.avatarContainer}>
					<img src={avatarStub} className={styles.avatar} />
				</div>
				<div className={styles.infos}>
					<h2 className={styles.name} title={title}>{title}</h2>
				</div>
				<div className={styles.actions}>
					<button
						className={`${styles.userActionButton} ${styles.userActionInviteMember}`}
						type="button"
						onClick={this.triggerInvitation}
					>
						<img
							src={inviteIcon}
							className={styles.userActionIcon}
							title={t("animator.box.list.action_invite")}
						/>
					</button>
					<button
						className={`${styles.userActionButton} ${styles.userActionDetach}`}
						type="button"
						onClick={this.triggerResidentDetachment}
					>
						<img
							src={detachIcon}
							className={styles.userActionIcon}
							title={t("animator.box.list.action_detach")}
						/>
					</button>
				</div>
			</Card>
		);
	}

	private renderUnpaired() {
		const { box: { roomNumber } } = this.props;
		return (
			<Card className={`${styles.boxItem} ${styles.boxUnpaired}`}>
				<div className={styles.avatarContainer}>
					<img src={avatarStub} className={styles.avatar} />
				</div>
				<div className={styles.infos}>
					<h2 className={styles.name} title={roomNumber}>{roomNumber}</h2>
				</div>
				<Button
					type="button"
					onClick={this.triggerResidentBinding}
				>{
					t("animator.box.list.action_pair")
				}</Button>
			</Card>
		);
	}

	private triggerResidentBinding = () => {
		this.props.bindResident(this.props.box);
	}

	private triggerResidentDetachment = () => {
		this.props.detachResident(this.props.box);
	}

	private triggerInvitation = () => {
		this.props.inviteMember(this.props.box);
	}
}

interface BoxCreationFormProps {
	onCreationCompleted: (pairingInfo: BoxCreationFormState) => Promise<void>;
	onCloseRequested: () => void;
}
interface BoxCreationFormState extends FormState {
	roomNumber: string;
	residentName: string;
	pairingCode: number[];
}
class BoxCreationForm extends Component<BoxCreationFormProps, BoxCreationFormState> {
	state: BoxCreationFormState = {
		roomNumber: "",
		residentName: "",
		pairingCode: []
	};

	render() {
		const { error, loading } = this.state;
		return (
			<form action="#" className={styles.form} onSubmit={this.onSubmit}>
				<fieldset role="group" aria-labelledby="box-creation-info-title">
					<h2 id="box-creation-info-title">{t("animator.box.create.fieldset_resident_title")}</h2>
					<p>{t("animator.box.create.fieldset_resident_description")}</p>
					<div className={Modal.styles.row}>
						<Input
							type="text"
							placeholder={t("animator.box.create.field_location_name")}
							onChange={this.onRoomNumberChanged}
							required
						/>
						<Input
							type="text"
							placeholder={t("animator.box.create.field_user_name")}
							onChange={this.onResidentNameChanged}
						/>
					</div>
				</fieldset>
				<fieldset role="group" aria-labelledby="box-creation-code-title">
					<h2 id="box-creation-code-title">{t("animator.box.create.fieldset_pairing_title")}</h2>
					<p>{t("animator.box.create.fieldset_pairing_description")}</p>
					<PairingCodeInput
						code={this.state.pairingCode}
						onCodeChanged={this.onPairingCodeChanged}
					/>
				</fieldset>
				{ error && <div className="error" style={{marginTop: "10px"}}><p>{error}</p></div> }
				<div className={`${Modal.styles.row} ${Modal.styles.buttonRow}`}>
					<Button
						styleType="bordered"
						className={Modal.styles.button}
						onClick={this.close}
						type="reset"
					>{t("animator.box.create.action_cancel")}</Button>
					<Button
						className={Modal.styles.button}
						type="submit"
						disabled={!this.validate() || loading}
					>{t("animator.box.create.action_submit")}</Button>
				</div>
			</form>
		);
	}

	private onResidentNameChanged: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ residentName: e.currentTarget.value });
	private onRoomNumberChanged: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ roomNumber: e.currentTarget.value });
	private onPairingCodeChanged = (code: number[]) => this.setState({ pairingCode: code.slice(0, 6) });

	private onSubmit: FormEventHandler = (e) => {
		e.preventDefault();
		if (!this.validate()) { return; }
		this.setState({ loading: true, error: null},
			() => this.props.onCreationCompleted(this.state)
				.catch(error => this.setState({ error: error.message, loading: false }))
			);
	}

	private validate = (): boolean => {
		const { roomNumber, pairingCode } = this.state;
		return (roomNumber && roomNumber !== "")
			&& pairingCode.length === 6;
	}

	private close: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		this.props.onCloseRequested();
	}
}

interface PairingCodeInputProps { code: number[]; onCodeChanged: (code: number[]) => void; }
class PairingCodeInput extends Component<PairingCodeInputProps> {

	render() {
		return (
			<div className={styles.pairingCodeInput}>
				<div className={styles.codeInput}>
					<div className={styles.codeField}>
						{this.props.code.map(this.toIcon)}
					</div>
					<button
						className={`${styles.codeButton} ${styles.deleteButton}`}
						type="button"
						title="Delete"
						onClick={this.deleteCodeKey}
					>
						<img
							src={deleteButtonIcon}
							className={styles.codeIcon}
						/>
					</button>
				</div>
				<div className={styles.codeIme}>
					<div className="codeImeLine">{
						Array.from({ length: 5 }, (_, index) => (
							<button
								key={index}
								className={styles.codeButton}
								type="button"
								value={index}
								onClick={this.pushCodeKey}
							>{
								this.toIcon(index, index)
							}</button>
						))
					}</div>
					<div className="codeImeLine">{
						Array.from({ length: 5 }, (_, index) => (index += 5,
							<button
								key={index}
								className={styles.codeButton}
								type="button"
								value={index}
								onClick={this.pushCodeKey}
							>{
								this.toIcon(index, index)
							}</button>
						))
					}</div>
				</div>
			</div>
		);
	}

	private toIcon = (codePart: number, index: number) => (
		<img
			key={index}
			src={this.getIconFor(codePart)}
			className={styles.codeIcon}
		/>
	)

	private getIconFor = (codeKey: number) => ([
		bell,
		heart,
		star,
		moon,
		note,
		feather,
		sun,
		clover,
		candy,
		home,
	][codeKey])

	private pushCodeKey: MouseEventHandler<HTMLButtonElement> = (event) => {
		const { value } = event.currentTarget;
		this.props.onCodeChanged([...this.props.code, +value]);
	}

	private deleteCodeKey: MouseEventHandler = () => this.props.onCodeChanged(this.props.code.slice(0, -1));
}

interface BoxBindingFormProps {
	box: Box;
	onBindingCompleted: (pairingInfo: BoxBindingFormState) => Promise<void>;
	onCloseRequested: () => void;
}
interface BoxBindingFormState extends FormState {
	residentName: string;
}
class BoxBindingForm extends Component<BoxBindingFormProps, BoxBindingFormState> {
	state: BoxBindingFormState = {
		residentName: "",
	};

	render() {
		const { error, loading } = this.state;
		return (
			<form action="#" className={styles.form} onSubmit={this.onSubmit}>
				<fieldset role="group" aria-labelledby="box-creation-info-title">
					<h2 id="box-creation-info-title">{t("animator.box.create.fieldset_resident_title")}</h2>
					<p>{t("animator.box.create.fieldset_resident_description")}</p>
					<div className={Modal.styles.row}>
						<Input
							type="text"
							placeholder={t("animator.box.create.field_location_name")}
							value={this.props.box.roomNumber}
							readOnly
							disabled
						/>
						<Input
							type="text"
							placeholder={t("animator.box.create.field_user_name")}
							onChange={this.onResidentNameChanged}
							required
						/>
					</div>
				</fieldset>
				{ error && <div className="error" style={{marginTop: "10px"}}><p>{error}</p></div> }
				<div className={`${Modal.styles.row} ${Modal.styles.buttonRow}`}>
					<Button
						styleType="bordered"
						className={Modal.styles.button}
						onClick={this.close}
						type="reset"
					>{t("animator.box.create.action_cancel")}</Button>
					<Button
						className={Modal.styles.button}
						type="submit"
						disabled={!this.validate() || loading}
					>{t("animator.box.create.action_submit")}</Button>
				</div>
			</form>
		);
	}

	private onResidentNameChanged: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ residentName: e.currentTarget.value });

	private onSubmit: FormEventHandler = (e) => {
		e.preventDefault();
		if (!this.validate()) { return; }

		this.setState({ loading: true, error: null},
			() => this.props.onBindingCompleted(this.state)
				.catch(error => this.setState({ error: error.message, loading: false }))
			);
	}

	private validate = (): boolean => {
		const { residentName } = this.state;
		return residentName && residentName !== "";
	}

	private close: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		this.props.onCloseRequested();
	}
}
