import { AxiosInstance } from "axios";
import { AxiosResponse } from "axios";
import { t } from "i18n-js";
import { inject, injectable } from "inversify";
import { flow, observable } from "mobx";
import { SundayError } from "../auth/authService";
import { EventService } from "../shared/eventService";
import { TYPES } from "./di";
import { Structure, StructureCreation } from "./structure";

export class StructureError extends SundayError { constructor(readonly message: string) { super(message); } }

export class DuplicateAnimatorError extends StructureError { constructor() { super(t("admin.structure.errors.duplicate_animator")); } }
export class StructureCreationError extends StructureError { constructor() { super(t("admin.structure.errors.creation")); } }
export class StructureFetchError extends StructureError { constructor() { super(t("admin.structure.errors.fetch")); } }

@injectable()
export class StructureService {
	@observable initialized: boolean = false;

	@observable all: Structure[] = [];

	constructor(
		@inject(TYPES.APIClient) private readonly client: AxiosInstance,
		@inject(TYPES.Events) private readonly eventService: EventService,
	) {
		this.fetch();
	}

	create = flow(function*(this: StructureService, form: StructureCreation) {
		try {
			const { data: structure }: AxiosResponse<Structure> = yield this.client.post("/structure", form);
			this.all = [structure, ...this.all];
			return structure;
		} catch (error) {
			console.warn("An error occurred while fetching the structures", error);
			if (error.response.status === 409) {
				throw new DuplicateAnimatorError();
			}
			throw new StructureCreationError();
		}
	});

	private fetch = flow(function*(this: StructureService) {
		try {
			const { data: { structures } }: AxiosResponse<StructureResponse> = yield this.client.get("/structure");
			this.all = structures;
		} catch (error) {
			console.warn("An error occurred while fetching the structures", error);

			this.eventService.events.push({
				message: t("admin.structure.errors.fetch"),
				type: "danger"
			});
		}
	});
}

interface StructureResponse {
	structures: Structure[];
}
