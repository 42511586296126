import logo from "../../assets/images/signup/logo.svg";
import styles from "./authScreen.scss";

import { t } from "i18n-js";
import { resolve } from "inversify-react";
import React, { ChangeEventHandler, FormEventHandler, PureComponent } from "react";
import { TYPES } from "../di";
import { Button, Card, Input, Version } from "../shared";
import { AuthService } from "./authService";

interface AuthScreenState {
	isSubmiting: boolean;
	displayLoginForm: boolean;
	loginError?: string;
	passwordResetError?: string;
}

class AuthScreen extends PureComponent<{}, AuthScreenState> {
	state: AuthScreenState = {
		isSubmiting: false,
		displayLoginForm: true,
	};

	@resolve(TYPES.Auth) private readonly authService: AuthService;

	render() {
		return (
			<div className={styles.authScreen}>
				<Card className={styles.formCard}>{
					this.state.displayLoginForm
					? (
						<LoginForm
							onSubmit={this.onLoginSubmit}
							onDisplayPasswordReset={this.displayPasswordForm}
							isSubmiting={this.state.isSubmiting}
							error={this.state.loginError}
						/>
					) : (
						<PasswordResetForm
							onSubmit={this.onPasswordSubmit}
							onDisplayLogin={this.displayLoginForm}
							isSubmiting={this.state.isSubmiting}
							error={this.state.passwordResetError}
						/>
					)
				}</Card>
				<Version className={styles.version} />
			</div>
		);
	}

	private onLoginSubmit = async (email, password) => {
		try {
			this.setState({ isSubmiting: true });
			await this.authService.login({ email, password });
		} catch (error) {
			this.setState({ loginError: error.message || t("auth.errors.default") });
		} finally {
			this.setState({ isSubmiting: false });
		}
	}

	private onPasswordSubmit = async (email) => {
		try {
			this.setState({ isSubmiting: true });
			await this.authService.resetPassword({ email });
			this.setState({ loginError: t("auth.password_reset_info") });
			this.displayLoginForm();
		} catch (error) {
			this.setState({ passwordResetError: error.message || t("auth.errors.default") });
		} finally {
			this.setState({ isSubmiting: false });
		}
	}

	private displayLoginForm = () => this.setState({ displayLoginForm: true });
	private displayPasswordForm = () => this.setState({ displayLoginForm: false });
}

interface LoginFormProps {
	onSubmit: (email: string, password: string) => void;
	onDisplayPasswordReset: () => void;
	isSubmiting?: boolean;
	error?: string;
}

interface LoginFormState {
	email: string;
	password: string;
}

class LoginForm extends PureComponent<LoginFormProps, LoginFormState> {
	state = {
		email: "",
		password: "",
	};

	render() {
		return (
			<form
				className={styles.formContainer}
				onSubmit={this.onSubmit}
			>
				<img src={logo} className={styles.logo} />
				<Input
					type="email"
					autoComplete="username"
					placeholder={t("auth.field_email")}
					className={styles.input}
					value={this.state.email}
					onChange={this.setEmail}
				/>
				<Input
					type="password"
					autoComplete="current-password"
					placeholder={t("auth.field_password")}
					className={styles.input}
					value={this.state.password}
					onChange={this.setPassword}
				/>
				{ this.props.error && (
					<p className="error">{this.props.error}</p>
				)}
				<Button
					type="submit"
					className={styles.submit}
					disabled={this.props.isSubmiting}
				>{t("auth.action_submit_login")}</Button>
				<Button
					type="button"
					styleType="link"
					onClick={this.props.onDisplayPasswordReset}
					disabled={this.props.isSubmiting}
				>{t("auth.link_forgot_password")}</Button>
			</form>
		);
	}

	private setEmail: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ email: e.target.value });
	private setPassword: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ password: e.target.value });

	private onSubmit: FormEventHandler = async (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state.email, this.state.password);
	}
}

interface PasswordResetFormProps {
	onSubmit: (email: string) => void;
	onDisplayLogin: () => void;
	isSubmiting?: boolean;
	error?: string;
}

interface PasswordResetFormState {
	email: string;
}

class PasswordResetForm extends PureComponent<PasswordResetFormProps, PasswordResetFormState> {
	state = {
		email: "",
	};

	render() {
		return (
			<form
				className={styles.formContainer}
				onSubmit={this.onSubmit}
			>
				<img src={logo} className={styles.logo} />
				<Input
					type="email"
					autoComplete="username"
					placeholder={t("auth.field_email")}
					className={styles.input}
					value={this.state.email}
					onChange={this.setEmail}
				/>
				{this.props.error && (
					<p className="error">{this.props.error}</p>
				)}
				<Button
					type="submit"
					className={styles.submit}
					disabled={this.props.isSubmiting}
				>{t("auth.action_submit_password")}</Button>
				<Button
					type="button"
					styleType="link"
					disabled={this.props.isSubmiting}
					onClick={this.props.onDisplayLogin}
				>{t("auth.link_login")}</Button>
			</form>
		);
	}

	private setEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
		this.setState({ email: e.target.value });
	}

	private onSubmit: FormEventHandler = async (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state.email);
	}
}

export default AuthScreen;
