import avatarStub from "../../assets/images/stubs/avatar.svg";
import styles from "./structureListScreen.scss";

import { t } from "i18n-js";
import { resolve } from "inversify-react";
import { observer } from "mobx-react";
import React, { ChangeEventHandler, Component, FormEventHandler, MouseEventHandler, PureComponent } from "react";
import { Button, Card, ImageService, Input, Modal } from "../shared";
import { Alert } from "../shared";
import { EventsDisplay } from "../shared/eventsDisplay";
import { EventService } from "../shared/eventService";
import { FormState } from "../shared/formState";
import { TYPES } from "./di";
import { Structure, StructureCreation } from "./structure";
import { StructureError, StructureService } from "./structureService";

interface StructureListScreenState {
	isCreatingStructure: boolean;
}

@observer
export class StructureListScreen extends Component<{}, StructureListScreenState> {
	state = {
		isCreatingStructure: false
	};

	@resolve(TYPES.Structure)
	private readonly structureService: StructureService;

	@resolve(TYPES.Events)
	private readonly eventService: EventService;

	render() {
		return (
			<>
				<main className={styles.screen}>
					<div className="container">
						<header className={styles.header}>
							<h1>{t("admin.structure.list.title")}</h1>
							<Button onClick={this.openStructureCreation}>{t("admin.structure.list.action_add")}</Button>
						</header>
						<EventsDisplay />
						<ul className={styles.list}>{
							this.structureService.all.map(s => (
								<li key={s.id} className={styles.listItem}>
									<StructureItem structure={s} />
								</li>
							))
						}</ul>
					</div>
					<Modal
						isOpen={this.state.isCreatingStructure}
						onRequestClose={this.closeStructureCreation}
						title={t("admin.structure.create.title")}
					>
						<StructureCreationForm
							onStructureSubmit={this.onStructuresSubmit}
							onCloseRequested={this.closeStructureCreation}
						/>
					</Modal>
				</main>
			</>
		);
	}

	private openStructureCreation = () => this.setState({ isCreatingStructure: true });
	private closeStructureCreation = () => this.setState({ isCreatingStructure: false });
	private onStructuresSubmit = async (structure: StructureCreation) => {
		await this.structureService.create(structure);
		this.closeStructureCreation();
	}
}

interface StructureItemProps {
	structure: Structure;
}

class StructureItem extends PureComponent<StructureItemProps> {
	render() {
		const { structure: { displayName, avatarUrl }} = this.props;
		return (
			<Card className={styles.structure}>
				<div className={styles.avatarContainer}>
					<img src={avatarUrl || avatarStub} className={styles.avatar} />
				</div>
				<div className={styles.infos}>
					<h2 className={styles.name}>{displayName}</h2>
				</div>
			</Card>
		);
	}
}

interface StructureCreationFormProps {
	onStructureSubmit: (s: StructureCreation) => Promise<void>;
	onCloseRequested: () => void;
}

interface StructureCreationFormState extends FormState {
	form: StructureCreation;
}

class StructureCreationForm extends React.PureComponent<StructureCreationFormProps, StructureCreationFormState> {
	state = {
		form: {},
		error: null,
		loading: false
	};

	@resolve(TYPES.Image) private readonly imageService: ImageService;

	render() {
		const { error, loading } = this.state;
		return (
			<form onSubmit={this.submit} style={{ display: "flex", flexDirection: "column" }}>
				<Input
					type="file"
					accept="image/png,image/jpeg"
					placeholder={t("admin.structure.create.field_avatar")}
					onChange={this.setAvatar}
					className={Modal.styles.row}
				/>
				<Input
					placeholder={t("admin.structure.create.field_name")}
					onChange={this.setName}
					autoFocus={true}
					className={Modal.styles.row}
				/>
				<Input
					placeholder={t("admin.structure.create.field_animator_email")}
					onChange={this.setEmail}
					className={Modal.styles.row}
				/>

				{ error && <p className="error">{error}</p>}

				<div className={`${Modal.styles.row} ${Modal.styles.buttonRow}`}>
					<Button
						styleType="bordered"
						className={Modal.styles.button}
						onClick={this.close}
						type="reset"
					>
						{t("admin.structure.create.action_cancel")}
					</Button>
					<Button
						className={Modal.styles.button}
						type="submit"
						disabled={!this.validate() || loading}
					>
						{t("admin.structure.create.action_submit")}
					</Button>
				</div>
			</form>
		);
	}

	private close: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		this.props.onCloseRequested();
	}

	private setAvatar: ChangeEventHandler<HTMLInputElement> = async (e) => {
		try {
			const file = e.target.files[0];
			if (!file) { throw new Error("No file selected"); }

			const resized = await this.imageService.downscale(e.target.files[0], this.imageService.avatarMaxDimensions);
			// tslint:disable-next-line:no-shadowed-variable
			const avatar = await new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = (re) => resolve((re.target as any).result as string);
				reader.readAsDataURL(resized);
			});
			this.setState(prevState => ({ form: { ...prevState.form, avatar } }));
		} catch (err) {
			console.error(err);
		}
	}

	private setName: ChangeEventHandler<HTMLInputElement> = (e) => {
		const name = e.target.value;
		this.setState(prevState => ({ form: { ...prevState.form, name }}));
	}

	private setEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
		const email = e.target.value;
		this.setState(prevState => ({ form: { ...prevState.form, email }}));
	}

	private submit: FormEventHandler = async (e) => {
		e.preventDefault();
		const structure: StructureCreation = this.state.form;
		const { onStructureSubmit } = this.props;

		if (!this.isValid(structure) || !onStructureSubmit) { return; }

		this.setState({ loading: true, error: null},
			() => onStructureSubmit(structure)
				.then(() => this.setState({ error: null, loading: false }))
				.catch(error => this.setState({ error: error.message, loading: false }))
			);
	}

	private validate = (): boolean => {
		return this.isValid(this.state.form);
	}

	private isValid = (structure: StructureCreation) => {
		return structure.name && structure.name !== "" && structure.email && structure.email !== "";
	}

}
