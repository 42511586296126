import documentAdd from "~/assets/images/document/document_add.svg";
import shareIcon from "~/assets/images/document/share.svg";
import trashIcon from "~/assets/images/shared/trash.svg";
import styles from "./documentListScreen.scss";

import { t } from "i18n-js";
import { resolve } from "inversify-react";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React, { ChangeEventHandler, Component, DragEventHandler } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Button, ImageService, Input, Modal } from "../../shared";
import { EventsDisplay } from "../../shared/eventsDisplay";
import { EventService } from "../../shared/eventService";
import { FormState } from "../../shared/formState";
import { Loader } from "../../shared/loader";
import { TYPES } from "../di";
import { StructureHeader } from "../structureHeader";
import { Document } from "./document";
import { DocumentService } from "./documentService";

const MAX_FILE_SIZE = 40 * 1024 * 1024; // 40 MB
const ACCEPTED_TYPES = "image/jpeg,image/jpg,image/png,video/mp4,video/avi,video/mkv";

interface DocumentListScreenState {
	isSharing: boolean;
	isDeleting: boolean;
}

@observer
export class DocumentListScreen extends Component<{}, DocumentListScreenState> {
	state: DocumentListScreenState = {
		isSharing: false,
		isDeleting: false
	};

	@resolve(TYPES.Document) documentService: DocumentService;
	@resolve(TYPES.Events) eventService: EventService;

	@computed private get containerStyle(): string {
		const containerStyles = ["container", styles.container];
		if (!this.documentService.initialized) {
			containerStyles.push(styles.loading);
		} else if (!this.documentService.all.length) {
			containerStyles.push(styles.grow);
		}

		return containerStyles.join(" ");
	}

	render() {
		return (
			<>
				<main className={this.containerStyle}>
					<header className={styles.header}>
						<StructureHeader />
					</header>
					<EventsDisplay />
					{
						!this.documentService.initialized
						? this.renderSkeleton()
						: this.documentService.all.length
							? this.renderList()
							: (
								<div className={styles.empty}>
									<img src={documentAdd} />
									<h2 className={styles.emptyTitle}>{t("animator.document.list.empty_title")}</h2>
									<p className={styles.emptyText}>{t("animator.document.list.empty_description")}</p>
									<Button onClick={this.openSharingForm}>{t("animator.document.list.action_add")}</Button>
								</div>
							)
					}
					<button
						type="button"
						className={styles.fab}
						onClick={this.openSharingForm}
						title={t("animator.document.list.action_add")}
					>
						<img
							src={shareIcon}
							alt={t("animator.document.list.action_add")}
						/>
					</button>
				</main>
				<Modal
					isOpen={this.state.isSharing}
					title={t("animator.document.create.title")}
					onRequestClose={this.closeSharingForm}
				>
					<DocumentSharingForm
						onSubmit={this.submitDocument}
					/>
				</Modal>
			</>
		);
	}

	private renderList(): JSX.Element {
		const documents = this.documentService.all;
		if (!documents.length) {
			return null;
		}

		return (
			<InfiniteScroll
				initialLoad={false}
				loadMore={this.loadNextPage}
				hasMore={this.documentService.hasMore}
			>
				<ul className={styles.documentGrid}>{
					documents.map(document => (
						<li
							key={document.id}
							className={styles.documentItem}
						>
							{ document.type.startsWith("video/") ?
								<video className={styles.sharingVideo} src={document.url} controls>
									Your browser does not supper the {document.type} video format.
								</video>
								:	<img src={document.url} />
							}
							{document.message && <p className={styles.documentCaption}>{document.message}</p>}
							<button
								className={styles.deleteIcon}
								onClick={() => this.deleteDocument(document)}
							>
								<img src={trashIcon} />
							</button>
						</li>
					))
				}</ul>
			</InfiniteScroll>
		);
	}

	private renderSkeleton(): JSX.Element {
		return (
			<ul className={`${styles.documentGrid} ${styles.skelGrid}`}>{
				Array.from({length: 12}, (_, index) => (
					<li key={index} className={`${styles.documentItem} ${styles.skel}`} />
				))
			}</ul>
		);
	}

	private openSharingForm = () => this.setState({ isSharing: true });
	private closeSharingForm = () => this.setState({ isSharing: false });
	private submitDocument = (data) => {
			return this.documentService.create(data)
				.then(() => this.closeSharingForm());
	}

	private deleteDocument = (document: Document) => {
		this.setState({ isDeleting: true }, async () => {
			try {
				if (confirm(t("animator.document.delete.confirm"))) {
					await this.documentService.delete(document);
				}
			} catch (error) {
				this.eventService.events.push({ message: error.message, type: "danger" });
			} finally {
				this.setState({ isDeleting: false });
			}
		});
	}

	private loadNextPage = () => this.documentService.fetchNext();
}

interface DocumentSharingFormProps {
	onSubmit: (data: { content: Blob; message?: string; }) => Promise<void>;
}

interface DocumentSharingFormState extends FormState {
	content?: Blob;
	thumbnail?: string;
	message?: string;
}

class DocumentSharingForm extends Component<DocumentSharingFormProps, DocumentSharingFormState> {
	state: DocumentSharingFormState = {};

	@resolve(TYPES.Image) private readonly imageService: ImageService;
	private readonly fileInput = React.createRef<HTMLInputElement>();

	render() {
		const { error, content, loading, thumbnail } = this.state;
		return (
			<form
				onSubmit={this.onSubmit}
				className={styles.sharingContainer}
			>
				<div>
					<div
						className={styles.sharingThumbnailContainer}
						onDrop={this.setFileFromDrop}
						onDragOver={this.checkImageType}
						onClick={this.triggerFileInput}
						onKeyPress={this.triggerFileInput}
						tabIndex={0}
					>
						<input
							type="file"
							ref={this.fileInput}
							onChange={this.setFileFromInput}
							style={{ display: "none" }}
							accept={ACCEPTED_TYPES}
						/>
						{ content && content.type.startsWith("video/") ?
							<video className={styles.sharingVideo} src={thumbnail} controls autoPlay>
								Your browser does not supper the {content.type} video format.
							</video>
							: <img
								className={styles.sharingThumbnail}
								src={this.state.thumbnail}
							/>
						}
					</div>
				</div>
				<Input
					placeholder={t("animator.document.create.field_sweet_word")}
					value={this.state.message}
					onChange={this.setMessage}
				/>
				{ error && <div className="error">{error}</div>}
				<div className={`${Modal.styles.row} ${Modal.styles.buttonRow}`}>
					<Button
						className={Modal.styles.button}
						disabled={!this.validate() || loading}
					>
						{ t("animator.document.create.action_submit") }
						{ loading && <Loader size={15} className={styles.loader}/> }
					</Button>
				</div>
			</form>
		);
	}

	private checkImageType: DragEventHandler = (evt) => {
		evt.preventDefault();
		console.log(evt.dataTransfer);
	}

	private setFileFromDrop: DragEventHandler = (evt) => {
		evt.preventDefault();

		let file: File;
		if (evt.dataTransfer.items) {
			const transferItem = evt.dataTransfer.items[0];
			if (transferItem.kind === "file") {
				file = transferItem.getAsFile();
			}
		} else {
			file = evt.dataTransfer.files[0];
		}

		this.setFile(file);
	}

	private setFileFromInput: ChangeEventHandler<HTMLInputElement> = (ev) => {
		const file = ev.target.files[0];
		this.setFile(file);
	}

	private setFile = async (file: File) => {
		if (!file) { return; }

		let content = file as Blob;
		if (file.type.startsWith("image/")) {
			content = await this.imageService.downscale(file, this.imageService.contentDimensions);
		}

		this.setState(({ thumbnail }) => {
			if (thumbnail) { URL.revokeObjectURL(thumbnail); }

			if (content.size >= MAX_FILE_SIZE) {
				return { error: t("animator.document.errors.file_too_large"), content: null, thumbnail: null };
			}

			return { error: null, content, thumbnail: URL.createObjectURL(content) };
		});
	}

	private setMessage: ChangeEventHandler<HTMLInputElement> = (evt) => this.setState({ message: evt.currentTarget.value });

	private triggerFileInput = () => {
		this.fileInput.current.click();
	}

	private validate = () => {
		return !!this.state.content;
	}

	private onSubmit = (evt) => {
		evt.preventDefault();
		if (!this.validate()) { return; }
		const { content, message } = this.state;

		this.setState({ loading: true, error: null},
			() => this.props.onSubmit({ content, message })
				.then(() => this.setState({ error: null, loading: false }))
				.catch(error => this.setState({ error: error.message, loading: false }))
			);
	}
}
