import { Container } from "inversify";
import { Provider } from "inversify-react";
import React, { Component } from "react";
import { Route } from "react-router";
import Header from "../nav/header";
import { TYPES } from "./di";
import { StructureListScreen } from "./structureListScreen";
import { StructureService } from "./structureService";

export class AdminFlow extends Component {

	private container: Container;

	constructor(props: {}) {
		super(props);
		this.container = new Container();
		this.container.bind<StructureService>(TYPES.Structure).to(StructureService);
	}

	componentWillUnmount() {
		this.container.unbindAll();
		console.log("Container was unbound");
	}

	render() {
		return (
			<Provider container={this.container}>
				<>
					<Header />
					<Route path="/" component={StructureListScreen} />
				</>
			</Provider>
		);
	}
}
