import { AxiosInstance, AxiosResponse } from "axios";
import { t } from "i18n-js";
import { inject, injectable } from "inversify";
import { action, computed, flow, observable } from "mobx";
import { SundayError } from "~/app/auth/authService";
import { Structure } from "../../admin/structure";
import { ResultPage } from "../../shared";
import { EventService } from "../../shared/eventService";
import { TYPES } from "../di";
import { Box } from "./box";

export class BoxPairError extends SundayError { constructor() { super(t("animator.box.errors.pair")); } }

export class BoxError extends SundayError { constructor() { super(t("animator.box.errors.default")); } }

@injectable()
export class BoxService {
	@observable all: Box[] = [];
	@observable initialized: boolean = false;
	@computed get hasMore() { return this.cursor !== null; }

	@observable private cursor: undefined | string | null;
	private readonly structureUrl = `/structure/${this.structure.id}`;

	constructor(
		@inject(TYPES.APIClient) private readonly client: AxiosInstance,
		@inject(TYPES.Structure) private readonly structure: Structure,
		@inject(TYPES.Events) private readonly eventService: EventService
	) {
		this.fetch();
	}

	create = flow(function* create(this: BoxService, data: PairingRequest) {
		try {
			yield this.client.post(`/pair`, data);
			this.reload();
		} catch (error) {
			console.error("Failed to create a new box", error.response);
			if (error.response.status === 404) {
				throw new BoxPairError();
			}
			throw new BoxError();
		}
	});

	pair = flow(function* pair(this: BoxService, { boxId, ...request }: BindingRequest) {
		try {
			const { data: pairedBox }: AxiosResponse<BoxDto> = yield this.client.post(`${this.structureUrl}/boxes/${boxId}`, request);
			this.upsertBox(pairedBox);
		} catch (error) {
			console.error("Failed to pair box", error);
			throw new BoxError();
		}
	});

	getInvitationCode = flow(function* getInvitationCode(this: BoxService, { boxId }: InvitationCodeRequest) {
		try {
			const { data: { code } }: AxiosResponse<{ code: string}> = yield this.client.get("/invite", { params: {boxId} });
			return code;
		} catch (err) {
			throw new BoxError();
		}
	});

	invite = flow(function* invite(this: BoxService, { boxId, code, email }: InvitationRequest) {
		try {
			yield this.client.post("/invite", {
				boxId,
				email,
				inviteCode: code,
			});
		} catch (err) {
			throw new BoxError();
		}
	});

	detach = flow(function* detach(this: BoxService, { boxId }: DetachRequest) {
		const { data: pairedBox }: AxiosResponse<BoxDto> = yield this.client.delete(`${this.structureUrl}/boxes/${boxId}/resident`);
		this.upsertBox(pairedBox);
	});

	fetchNext = flow(function* fetchNext(this: BoxService) {
		if (this.hasMore) {
			yield this.fetch();
		}
	});

	private fetch = flow(function* fetch(this: BoxService) {
		try {
			const { data: { cursor, results: boxes} }: AxiosResponse<ResultPage<BoxDto>> = yield this.client.get(`${this.structureUrl}/boxes`, {
				params: {
					offset: this.cursor,
				},
			});
			this.cursor = cursor;
			this.all.push(...boxes);
		} catch (err) {
			console.warn(err);
			this.eventService.events.push({ message: t("animator.box.errors.fetch"), type: "danger" });
		} finally {
			this.initialized = true;
		}
	});

	@action
	private upsertBox(box: BoxDto) {
		const { id } = box;
		const boxIndex = this.all.findIndex(b => b.id === id);
		if (boxIndex >= 0) {
			this.all.splice(boxIndex, 1, box);
		} else {
			this.all.push(box);
		}
	}

	@action
	private reload() {
		this.initialized = false;
		this.all = [];
		this.cursor = undefined;
		this.fetch();
	}
}

export interface PairingRequest {
	structureId: string;
	roomNumber: string;
	residentName: string;
	pairingCode: string;
}

export interface BindingRequest {
	boxId: string;
	residentName: string;
}

export interface InvitationCodeRequest {
	boxId: string;
}

export interface InvitationRequest {
	boxId: string;
	code: string;
	email: string;
}

export interface DetachRequest {
	boxId: string;
}

interface BoxDto {
	id: string;
	roomNumber: string;
	residentName: string;
	photoCount: number;
	memberCount: number;
	paired: boolean;
}
