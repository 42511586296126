import React, { ButtonHTMLAttributes, PureComponent } from "react";

import styles from "./button.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	styleType: "plain" | "bordered" | "link";
}

export class Button extends PureComponent<ButtonProps> {
	static defaultProps = {
		styleType: "plain"
	};

	render() {
		const { className = "", styleType, ...props } = this.props;
		return(
			<button {...props} className={`${styles.button} ${styles[styleType] || ""} ${className}`} />
		);
	}
}
