import { AxiosInstance, AxiosResponse } from "axios";
import { t } from "i18n-js";
import { inject, injectable } from "inversify";
import { computed, flow, observable } from "mobx";
import { Structure } from "../../admin/structure";
import { SundayError } from "../../auth/authService";
import { ResultPage } from "../../shared";
import { EventService } from "../../shared/eventService";
import { TYPES } from "../di";
import { Document } from "./document";
import { UploadService } from "./uploadService";

export class DocumentUploadError extends SundayError { constructor() { super(t("animator.document.errors.upload")); } }
export class DocumentDeleteError extends SundayError { constructor() { super(t("animator.document.errors.delete")); } }
@injectable()
export class DocumentService {
	@observable all: Document[] = [];
	@observable initialized: boolean = false;
	@computed get hasMore() { return this.cursor !== null; }

	private cursor?: string = null;

	constructor(
		@inject(TYPES.Structure) private readonly structure: Structure,
		@inject(TYPES.APIClient) private readonly client: AxiosInstance,
		@inject(TYPES.Events) private readonly eventService: EventService,
		@inject(TYPES.Upload) private readonly uploadService: UploadService,
	) {
		this.fetch();
	}

	create = flow(function* create(this: DocumentService, request: DocumentCreationRequest) {
		try {
			const upload = yield this.uploadService.upload(request.content);
			const { data: documentDto}: AxiosResponse<DocumentDto> = yield this.client.post(`/structure/${this.structure.id}/document`, {
				uploadId: upload.id,
				sweetWord: request.message
			});

			this.all = [ DocumentConverter.convert(documentDto), ...this.all ];
		} catch (error) {
			console.error("Failed to upload & create the document", error);
			throw new DocumentUploadError();
		}
	});

	fetchNext = flow(function* fetchNext(this: DocumentService) {
		if (this.hasMore) {
			yield this.fetch();
		}
	});

	delete = flow(function* deleteDocument(this: DocumentService, document: Document) {
		try {
			yield this.client.delete(`/structure/${this.structure.id}/document/${document.id}`);
			this.all = this.all.filter(d => d.id !== document.id);
		} catch (error) {
			console.error(`Failed to delete the document ${document.id}`, error);
			throw new DocumentDeleteError();
		}
	});

	private fetch = flow(function* fetch(this: DocumentService) {
		try {
			const { data: page }: AxiosResponse<ResultPage<DocumentDto>> = yield this.client.get(`/structure/${this.structure.id}/document`, {
				 params: {
						offset: this.cursor || ""
					}
			});
			this.cursor = page.cursor;
			this.all.push(...page.results.map(DocumentConverter.convert));
		} catch (error) {
			console.warn(`An error occurred while fetching the documents for the structure ${this.structure.id}`, error);
			this.eventService.events.push({
				message: t("animator.document.errors.fetch"),
				type: "danger"
			});
		} finally {
			this.initialized = true;
		}
	});
}

interface DocumentCreationRequest {
	content: Blob;
	message?: string;
}

interface DocumentDto {
	id: string;
	contentType: string;
	url: string;
	sweetWord: string;
}
class DocumentConverter {
	static convert(dto: DocumentDto): Document {
		return {
			id: dto.id,
			url: dto.url,
			message: dto.sweetWord,
			type: dto.contentType
		};
	}
}
