import logo from "../../assets/images/signup/logo.svg";
import styles from "./loadingScreen.scss";

import React, { PureComponent } from "react";

export class LoadingScreen extends PureComponent {
	render() {
		return (
			<div className={styles.background}>
				<img
					src={logo}
					className={styles.loader}
					alt="Loading, please wait"
				/>
			</div>
		);
	}
}
