import { injectable } from "inversify";

@injectable()
export class ImageService {
	readonly contentDimensions: [number, number] = [1920, 1920 * 9 / 16];
	readonly avatarMaxDimensions: [number, number] = [256, 256];

	async downscale(source: Blob, [maxWidth, maxHeight]: [number, number]): Promise<Blob> {
		return new Promise<Blob>((fulfill, reject) => {
			const image = new Image();
			image.src = URL.createObjectURL(source);
			image.onerror = reject;
			image.onload = () => {
				let imageWidth = image.width;
				let imageHeight = image.height;

				if (imageWidth > imageHeight) {
					if (imageWidth > maxWidth) {
						imageHeight *= maxWidth / imageWidth;
						imageWidth = maxWidth;
					}
				} else {
					if (imageHeight > maxHeight) {
						imageWidth *= maxHeight / imageHeight;
						imageHeight = maxHeight;
					}
				}

				const canvas = document.createElement("canvas");
				canvas.width = imageWidth;
				canvas.height = imageHeight;
				image.width = imageWidth;
				image.height = imageHeight;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
				canvas.toBlob((blob) => fulfill(blob), source.type, 0.8);
			};
		});
	}
}
