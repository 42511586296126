import styles from "./error404.scss";

import { t } from "i18n-js";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Header from "../nav/header";
import { Button } from "./button";

export class Error404 extends PureComponent {
	render() {
		return (
			<>
				<Header />
				<div className={`container ${styles.container}`}>
					<p className={styles.code}>404</p>
					<h1 className={styles.title}>{t("common.errors.404.title")}</h1>
					<p className={styles.text}>{t("common.errors.404.body")}</p>
					<Link to="/">
						<Button>{t("common.errors.404.home_link")}</Button>
					</Link>
				</div>
			</>
		);
	}
}
