import "./style.scss";

import I18n from "i18n-js";
import { Provider } from "inversify-react";
import { observer } from "mobx-react";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "reflect-metadata";
import { UserFlow } from "~/app/user/userFlow";
import AuthScreen from "./app/auth/authScreen";
import { AuthService } from "./app/auth/authService";
import { container, lazyInject, TYPES } from "./app/di";
import { LoadingScreen, Modal, Version } from "./app/shared";
import * as translations from "./assets/strings";

declare global {
	var process: {
		env: {[envName: string]: string};
	};
}

I18n.locale = navigator.language;
I18n.defaultLocale = "en";
I18n.fallbacks = true;
I18n.translations = translations;

@observer
class App extends Component {

	@lazyInject(TYPES.Auth) private readonly authService: AuthService;

	render() {
		return (
			<Provider container={container}>
				<Router>{
					!this.authService.initialized
					? <Route path="/" component={LoadingScreen} />
					: !this.authService.session
						? (
							<Switch>
								<Route exact strict path="/" component={AuthScreen} />
								<Redirect to="/" />
							</Switch>
						) : <Route path="/" component={UserFlow} />
				}</Router>
			</Provider>
		);
	}
}

export default () => {
	console.log(`Application version: ${Version.versionString}`);

	const appRoot = "app";
	Modal.setAppElement(`#${appRoot}`);
	ReactDOM.render(<App />, document.getElementById(appRoot));
};
