import { t } from "i18n-js";
import { resolve } from "inversify-react";
import { CancellablePromise } from "mobx/lib/api/flow";
import React, { ChangeEventHandler, Component, FormEventHandler, MouseEventHandler } from "react";
import unorm from "unorm";
import { Button, FormState, Input, Modal } from "~/app/shared";
import { TYPES } from "../di";
import { Box } from "./box";
import styles from "./boxListScreen.scss";
import { BoxService } from "./boxService";

interface BoxInvitationFormProps {
	box: Box;
	onInvitationCompleted: (pairingInfo: BoxInvitationFormState) => Promise<void>;
	onCloseRequested: () => void;
	loading?: boolean;
	error?: string;
}

export interface BoxInvitationFormState extends FormState {
	email: string;
	code: string;
}

export default class BoxInvitationForm extends Component<BoxInvitationFormProps, BoxInvitationFormState> {
	state: BoxInvitationFormState = {
		email: "",
		code: "",
	};

	@resolve(TYPES.Box) private readonly boxService: BoxService;

	private codePromise: CancellablePromise<string>;

	componentDidMount() {
		this.codePromise = this.boxService.getInvitationCode({ boxId: this.props.box.id });
		this.codePromise.then((code) => this.setState({ code }));
	}

	componentWillUnmount() {
		this.codePromise.cancel();
	}

	render() {
		const { error, loading } = this.props;
		return (
			<form action="#" className={styles.form} onSubmit={this.onSubmit}>
				<fieldset role="group" aria-labelledby="box-invitation-code-title">
					<h2 id="box-invitation-code-title">{t("animator.box.invite.fieldset_code_title")}</h2>
					<p className={styles.invitationCode}>{this.state.code}</p>
					<p>{t("animator.box.invite.fieldset_code_description")}</p>
				</fieldset>
				<fieldset role="group" aria-labelledby="box-creation-info-title">
					<h2 id="box-creation-info-title">{t("animator.box.invite.fieldset_email_title")}</h2>
					<p>{t("animator.box.invite.fieldset_email_description")}</p>
					<div className={`${Modal.styles.row} ${styles.input}`}>
						<Input
							type="text"
							placeholder={t("animator.box.invite.field_email")}
							onChange={this.onEmailChanged}
							required
						/>
					</div>
				</fieldset>
				{ error && <div className="error" style={{marginTop: "10px"}}><p>{error}</p></div> }
				<div className={`${Modal.styles.row} ${Modal.styles.buttonRow}`}>
					<Button
						styleType="bordered"
						className={Modal.styles.button}
						onClick={this.close}
						type="reset"
					>{t("animator.box.invite.action_cancel")}</Button>
					<Button
						className={Modal.styles.button}
						type="submit"
						disabled={!this.validate() || loading}
					>{t("animator.box.invite.action_submit")}</Button>
				</div>
			</form>
		);
	}

	private onEmailChanged: ChangeEventHandler<HTMLInputElement> = (e) => this.setState({ email: e.currentTarget.value });

	private onSubmit: FormEventHandler = (e) => {
		e.preventDefault();
		if (!this.validate()) { return; }

		this.props.onInvitationCompleted(this.state);
	}

	private validate = (): boolean => {
		const { email } = this.state;
		return typeof email === "string"
			&& email !== ""
			&& !/[\u0300-\u036f]/.test(unorm.nfd(email)) // Pas d'accent
			&& /^([\w!#$%&'*+-/=?^`{|}~]+)(\.[\w!#$%&'*+-/=?^`{|}~]+)*@(?:[\w-]+)(?:\.[\w-]+)*$/i.test(email); // Structure globale
	}

	private close: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		this.props.onCloseRequested();
	}
}
