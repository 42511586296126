import styles from "./loader.scss";

import React, { HTMLAttributes, PureComponent } from "react";

export interface LoaderProps extends HTMLAttributes<HTMLElement> {
	size: number;
}

export class Loader extends PureComponent<LoaderProps> {
	render() {
		const { size } = this.props;
		return (
			<div style={{
				width: `${size}px`,
				height: `${size}px`
			}}
				{...this.props}
			>
				<div className={styles.ldsRing}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}
}
