import firebase from "firebase/app";

export class JWTSession {

	constructor(private readonly user: firebase.User) {
	}

	get token(): Promise<string> {
		return this.user.getIdToken();
	}

	get email(): string {
		return this.user.email;
	}

}
