import React, { HTMLProps, PureComponent } from "react";

export class Version extends PureComponent<HTMLProps<HTMLDivElement>> {
	private static hash = `${process.env.BUILD_HASH}`;
	private static version = `${process.env.BUILD_VERSION}`;
	private static date = Version.computeDate(`${process.env.BUILD_DATE}`);

	static versionString = `${Version.version}-${Version.hash}-${Version.date}`;

	render() {
		return (
			<aside
				{...this.props}
			>
				{Version.versionString}
			</aside>
		);
	}

	private static computeDate(buildVersion: string = "undefined") {
		const match = buildVersion.match(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/);
		if (!match || match.length !== 7) { return buildVersion; }
		const [, year, month, day, hour, minute, second] = match.map(p => parseInt(p, 10));
		return new Date(Date.UTC(year, month - 1, day, hour, minute, second)).toISOString();
	}
}
