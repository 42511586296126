import styles from "./card.scss";

import React, { PureComponent } from "react";

export class Card extends PureComponent<any> {
	render() {
		const { className = "", children, ...props } = this.props;
		return (
			<div {...props} className={`${styles.card} ${className}`}>
				{children}
			</div>
		);
	}
}
