import { AxiosInstance, AxiosResponse } from "axios";
import { inject, injectable } from "inversify";
import { computed, flow, observable } from "mobx";
import { Structure } from "../admin/structure";
import { TYPES } from "./di";
import { User } from "./user";

@injectable()
export class UserService {
	@observable user: User = null;
	@computed get initialized() { return this.user !== null; }

	constructor(
		@inject(TYPES.APIClient) private readonly client: AxiosInstance,
	) {
		this.init();
	}

	private init = flow(function* init(this: UserService) {
		const { data: userDetails }: AxiosResponse<SessionResponse> = yield this.client.get("session");
		const isAnimator = userDetails.roles.some(r => r === "ANIMATOR");
		this.user = {
			displayName: userDetails.username,
			avatarUrl: userDetails.avatarUrl,
			isAdmin: userDetails.roles.some(r => r === "ADMINISTRATOR"),
			isAnimator,
			structures: isAnimator ? userDetails.structures : undefined,
		};
	});
}

interface SessionResponse {
	username: string;
	avatarUrl: string;
	roles: ("USER" | "ADMINISTRATOR" | "ANIMATOR")[];
	structures: Structure[];
}
