import { Structure } from "../admin/structure";

export type User = Admin | Animator;

interface BaseUser {
	displayName: string;
	avatarUrl: string;
	isAdmin: boolean;
	isAnimator: boolean;
}

// tslint:disable-next-line:no-empty-interface Enforce type splitting
export interface Admin extends BaseUser {}

export interface Animator extends BaseUser {
	structures: Structure[];
}

export function isAnimator(user: User): user is Animator {
	return user.isAnimator;
}
