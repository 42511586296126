import React, { InputHTMLAttributes, PureComponent } from "react";

import styles from "./input.scss";

export class Input extends PureComponent<InputHTMLAttributes<HTMLInputElement>> {
	render() {
		const { type = "text", className = styles.input, ...props } = this.props;
		return(
			<input {...props} type={type} className={`${styles.input} ${className}`} />
		);
	}
}
